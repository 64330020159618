.user-guide {
  font-family: "Arial", sans-serif; // Choose a readable font
  color: white; // Enhanced contrast color
  text-align: left;
  max-width: 400px;
  margin-top: 10px;

  h1 {
    // Changed from h2 to h1
    font-size: 24px; // Slightly larger for primary heading
    margin-bottom: 10px; // Spacing after the heading
    margin-top: 10px;
    color: #dedede; // Add soft orange to the main heading
  }

  ol.steps {
    padding-left: 5px; // Padding to the left for numbers
    list-style: decimal inside none; // Ensures numbers are inside and there is no bullet point
    li {
      margin-bottom: 9px; // Spacing between list items
      strong {
        font-weight: bold; // Make the strong text bold
        color: #f0a76b; // Soft orange color for important labels
      }
    }
  }

  a {
    color: #c9c5f7; // Hyperlink color remains for visual distinction
    text-decoration: none; // No underline

    &:hover {
      text-decoration: underline; // Underline on hover
    }
  }

  p {
    margin-top: 20px; // Spacing before the closing paragraph
    color: white; // Keep the footer text color white
  }
}
