/* Add this CSS to your App.css or equivalent stylesheet */
@media only screen and (max-width: 768px) {
  /* Adjust width as needed */
  .hide-on-mobile {
    display: none;
  }
  .show-on-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; // This will take up the full height of the viewport
    width: 100vw; // This will take up the full width of the viewport
    text-align: center;
    font-size: 20px; // Adjust based on your preference
    color: white; // Adjust based on your preference
    background-color: rgba(
      0,
      0,
      0,
      0.7
    ); // Gives a semi-transparent dark background
  }
}
