video,
canvas {
  margin: 0;
  padding: 0;
  border: none;
  display: block; // Ensures no whitespace around inline elements
}

.control-label {
  color: #d7e8f4;
  padding-top: 5px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
}

h3 {
  margin-bottom: 10px;
}

h2 {
  color: #f0a76b;
  font-size: 20px;
  font-weight: 700;
}

video {
  object-fit: cover; /* Or try 'fill' to see if it changes behavior */
}

.App {
  text-align: center;
}

/* To increase the size of the checkbox and keep it centered */
.form-check-input {
  margin-left: 3px;
  margin-top: 2px;
  transform: scale(1.4); /* Adjust the scale to make the checkbox bigger */
  transform-origin: -2 0; /* Adjust the origin so scaling starts from the top left */
  margin-right: 8px; /* Space between checkbox and label */
  vertical-align: middle; /* Ensure the checkbox aligns vertically with the label */
  cursor: pointer;
}

/* Optional: Adjust label to align with the larger checkbox */
.control-label label {
  vertical-align: middle; /* Align the label vertically with the checkbox */
}

/* index.css */
.btn.btn-light.btn-extra-small {
  display: inline-block;
  padding: 0.2rem 0.5rem;
  font-size: 0.875rem; // Small button size
  line-height: 1.5;
  border-radius: 0.3rem;
  color: #000; // Black text color
  background-color: #f8f9fa; // Light greyish-silver background (close to white)
  border: 1px solid #ced4da; // Slightly darker grey border

  // Hover effect
  &:hover {
    color: #000;
    background-color: #e2e6ea; // Slightly darker on hover
    border-color: #dae0e5;
    text-decoration: none;
  }

  // Active state
  &:active {
    background-color: #dae0e5;
    border-color: #d6d8db;
  }

  // Focus state
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
  }

  // Disabled state
  &:disabled {
    background-color: #e9ecef;
    border-color: #dee2e6;
    color: #6c757d;
    opacity: 0.65;
  }
}

button + button {
  margin-left: 5px;
}

body {
  background-color: #111827 !important;
}

.main-container {
  background-color: #111827;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: center;
  font-size: 13px;
  color: white;

  .video-container {
    position: relative;
    width: 540px;
    height: 675px;
    padding-top: 15px;

    .piano {
      position: absolute;
      left: 0; // Centered horizontally
    }
  }

  .settings {
    padding-top: 15px;
    padding-left: 15px;
    gap: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
}

.recording-btns {
  margin-top: 10px;
  position: relative;
}

.recording {
  margin-top: 15px;

  .btn {
    position: relative;
  }
}
